import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import { Layout } from "../../components/layout";

const IndexPage = () => {
  return (
    <>
      <Layout title="Besuchshunde">
        <main className="container justify">
          <h1 className="mb-3">Besuchshund Paige</h1>
          <p>
            Ein ganz besonderer Termin für Paige und mich sind die Nachmittage
            bei den alten Damen und Herren im Alten- und Pflegeheim in
            Gernsbach.
          </p>
          <p>
            Paige weiß ganz genau, sobald das “ Besuchshunde-Halsband “ angelegt
            ist, geht es zur Arbeit und die sonst so temperamentvolle Paige
            verwandelt sich.
          </p>
          <p>
            In Gernsbach angekommen, darf noch einmal kurz eine Runde auf freiem
            Gelände gedreht werden und dann geht`s ab zu den Bewohnern ins Heim.
          </p>
          <p>
            Die Besuche sind angekündigt und so kann jeder Bewohner entscheiden,
            ob er in dieser Zeit den Kontakt wünscht - je nach dem steht die
            Zimmertür auf oder nicht. Paige hält ständig Kontakt mit mir und
            reagiert auf kleinste Handzeichen. Sie läuft ohne Leine frei in den
            Gängen und in die geöffneten Zimmer. Ganz besonders toll findet sie
            natürlich die Kuchen-Leckerli, die die alten Damen und Herren für
            sie bereit gehalten haben.
          </p>
          <p>
            Manchmal ist auch nur abwarten angesagt, ein bischen Abstand halten
            und geduldig zuhören. Paige ist ein ganz toller Zuhörer und spürt
            ganz genau wann und wieviel Zuwendung von ihr benötigt wird.
          </p>
          <div className="row justify-content-center my-3">
            <div className="col-md-4">
              <StaticImage
                className="shadow py-2"
                layout="fullWidth"
                loading="lazy"
                src="../../images/besuchshunde/besuchshunde1.jpg"
                alt="Besuchshunde1"
              />
            </div>
            <div className="col-md-6">
              <StaticImage
                className="shadow py-2"
                layout="fullWidth"
                loading="lazy"
                src="../../images/besuchshunde/besuchshunde2.jpg"
                alt="Besuchshunde2"
              />
            </div>
          </div>
          <p>
            Sind wir in einem Zimmer mit einem bettlägerigen älteren Bewohner
            nimmt sie sich zurück. Noch nie ist sie an einem Infusionsschlauch
            gestreift oder hat mit ihrem ständig schwingenden Schwanz einen
            Bewohner getroffen. Bei der kartenspielenden Runde im
            Aufenthaltsraum dagegen ist sie wie ein Wirbelwind. Sie spürt, dass
            diese Menschen mobiler sind und auch mal einen Nasenstüber am Arm
            vertragen. Sie bringt alle Spieler zum Lachen und kann auch mal den
            Spielkarten einen kleinen Schmatz verpassen. Beim Besuch einer
            bettlägerigen blinden Dame wird sie nicht müde ständig die Finger
            die sich über den Bettrand zu ihr hinstrecken sanft abzulecken und
            verharrt dabei ohne sich sonst zu bewegen.
          </p>
          <p>
            Nach diesem Nachmittag ist Paige richtig “ geschafft “ aber
            glücklich. Dann geht´s noch eine Runde schwimmen und dann fahren wir
            beide wieder nach Hause.
          </p>
          <div className="row justify-content-center my-3">
            <div className="col-md-6">
              <StaticImage
                className="shadow py-2"
                layout="fullWidth"
                loading="lazy"
                src="../../images/besuchshunde/besuchshunde3.jpg"
                alt="Besuchshunde3"
              />
            </div>
          </div>
          <p>
            Diese ehrenamtliche Arbeit im Verein “ Hundefreunde Hügelsheim “ ist
            sehr bereichernd für alle Beteiligten. Wenn es uns gelingt an diesem
            Nachmittag ein Lächeln auf das Gesicht zu zaubern, ein Gespräch sich
            entwickelt, eine zurückgezogene Seele sich kurz öffnet, eine vom
            Schlaganfall gezeichnete Hand bereitwillig ein Leckerlie gibt, ist
            das für uns wie ein Geschenk.
          </p>
          <p>
            Die offene und freundliche Art der Heimleitung und des Personals tut
            ihr übriges. Das ist keineswegs überall so.
          </p>
          <p>
            Es muss noch viel Überzeugungsarbeit geleistet und Vorurteile
            abgebaut werden.
          </p>
        </main>
      </Layout>
    </>
  );
};

export default IndexPage;
